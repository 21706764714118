<template>
  <div>
    <div v-if="PDMain">
      <!-- 顶部搜索框 -->
      <div class="usertop">
        <!-- 左侧搜索 -->
        <div>
          <el-form :inline="true" :model="formInline" class="demo-form-inline">
            <!-- <el-form-item>
              <el-select clearable @change="type" placeholder="选择种类" v-model="formInline.dict_type">
                <el-option key="" label="全部" value=""></el-option>
                <el-option v-for="item in dictList[160000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item>
              <el-input class="w-208" @input="search" @keydown.enter="search" v-model="formInline.drug_name" clearable
                placeholder="名称/拼音">
                <template #suffix>
                  <div @click="search" class="searchcss">
                    <i class="fa fa-search"></i>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="盘点时间：">
              <el-date-picker @change="search" clearable v-model="formInline.check_date" type="date" :clearable="false"
                value-format="YYYY-MM-DD" placeholder="请选择盘点时间">
              </el-date-picker>
            </el-form-item>
          </el-form>
        </div>
        <!-- 右侧按钮 -->
        <div>
          <el-button class="color-vice" type="primary" @click="exportTable">
            <i class="fa fa-arrow-down"></i>导出
          </el-button>
          <!-- <el-button class="color-green" type="primary" @click="PDClick">
            <i class="fa fa-arrow-up"></i>盘点
          </el-button> -->
        </div>
      </div>

      <!-- 表格内容 -->
      <div>
        <el-table class="sss" v-loading="loading" stripe :data="tableData" style="width: 100%" max-height="64vh" border>
          <!-- <el-table-column type="selection" width="55" /> -->
          <el-table-column prop="check_date" label="盘点日期" width="150" fixed/>
          <el-table-column label="名称" width="180" fixed>
            <template #default="scope">
              <span class="nameSet" @click="seeHistory(scope.row)">{{scope.row.drug.name}}</span>
            </template>
          </el-table-column>
          <el-table-column width="150" prop="order_no" label="入库单号" fixed/>
          <el-table-column width="180" label="批号" >
            <template #default="scope">
              <span>{{scope.row.batch_no}}</span>
            </template>
          </el-table-column>
          <el-table-column property="drug.medi_spec" label="规格" width="180" show-overflow-tooltip />
          <el-table-column width="120" property="drug.approval_no" label="国药准字" show-overflow-tooltip />
        <el-table-column width="180" property="drug.company" label="厂家" show-overflow-tooltip />
          <!-- <el-table-column prop="dict_type_name" label="类型" /> -->
          <el-table-column label="计算数量" width="150">
            <template #default="scope">
              <span>
                {{ scope.row.store_num }} {{ scope.row.drug.min_package_unit_name }}
                <template v-if="scope.row.store_sub_num">{{ scope.row.store_sub_num }} {{
                  scope.row.drug.min_unit_name }}</template>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="盘点数量" width="150">
            <template #default="scope">
              <span>
                {{ scope.row.check_num }} {{ scope.row.drug.min_package_unit_name }}
                <template v-if="scope.row.check_sub_num">{{ scope.row.check_sub_num }} {{
                  scope.row.drug.min_unit_name }}</template>
              </span>
            </template>
          </el-table-column>
          <!-- 入库单价 -->
          <el-table-column label="采购价" width="100">
            <template #default="scope">
              <span>{{ scope.row.buy_price }}元</span>
            </template>
          </el-table-column>
          <!-- 入库总额 -->
          <el-table-column label="采购总价" width="130">
            <template #default="scope">
              <span>{{ scope.row.total_buy_price }}元</span>
            </template>
          </el-table-column>
          <el-table-column width="100" label="零售价">
            <template #default="scope">
              <span>{{ scope.row.price }}元</span>
            </template>
          </el-table-column>
          <el-table-column width="130" label="零售总价">
            <template #default="scope">
              <span>{{ scope.row.total_sell_price }}元</span>
            </template>
          </el-table-column>
          <el-table-column label="盘点偏差" width="120">
            <template #default="scope">
                <span>
                  {{ scope.row.diff_num }} {{ scope.row.drug.min_package_unit_name }}
                  <template v-if="scope.row.diff_sub_num">{{ scope.row.diff_sub_num }} {{
                    scope.row.drug.min_unit_name }}</template>
                </span>
            </template>
          </el-table-column>
          <el-table-column property="operator.name" label="操作人" />
          <el-table-column prop="remark" label="备注" show-overflow-tooltip />
          <el-table-column fixed="right" label="操作" align="center" width="120">
            <template #default="scope">
              <el-button type="text" size="small" @click="modParient(scope.row)">
                <span class="txfonts modify">修改</span>
              </el-button>
              <el-popconfirm title="撤销后无法撤销，确认撤销吗 ?" @confirm="stockDelete(scope.row)">
                <template #reference>
                  <el-button type="text" size="small" style="color:red">
                    撤销
                  </el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination-box">
          <el-pagination v-model:currentPage="pageData.currentPage" v-model:page-size="pageData.pageSize"
            :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
            :total="pageData.totalSum" @size-change="handleSizeChange"
            @current-change="handleCurrentChange"></el-pagination>
        </div>
      </div>
    </div>
    <div v-if="!PDMain">
      <div class="display_flex top">
        <div class="search-top title-top">
          库存盘点/盘点
        </div>
        <div class="item">
          <el-button @click="PDDataClick" type="success">
            一键盘点
          </el-button>
          <el-button @click="PDBackClick" type="info">
            返回
          </el-button>
        </div>
      </div>
      <div class="tables">
        <div class="tables-left">
          <el-form :inline="true" :model="formData" class="demo-form-inline form-two">
            <!-- <el-form-item label="物品类型:">
              <el-select clearable @change="typePD" placeholder="选择种类" v-model="formData.dict_type">
                <el-option key="" label="全部" value=""></el-option>
                <el-option v-for="item in dictList[160000000]" :key="item.id" :label="item.name"
                  :value="item.code"></el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item label="物品名称:">
              <el-input class="w-208" @input="searchPD" v-model="formData.drug_name" clearable placeholder="名称/拼音">
                <template #suffix>
                  <div @click="searchPD" class="searchcss">
                    <i class="fa fa-search"></i>
                  </div>
                </template>
              </el-input>
            </el-form-item>
            <el-form-item label="盘点日期:">
              <el-date-picker @change="timeChange" v-model="formData.check_date" type="date" placeholder="盘点日期">
              </el-date-picker>
            </el-form-item>
          </el-form>
          <el-table class="sss" v-loading="dataListLoading" :row-style="rowStyle" :data="tableDataPD" border max-height="56vh"
            style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="55">
            </el-table-column>
            <el-table-column width="180" prop="name" label="名称" fixed show-overflow-tooltip>
              <template #default="scope">
                <span>{{scope.row.name}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column width="180" label="批号" fixed>
              <template #default="scope">
                <span>{{scope.row.batch_no}}</span>
              </template>
            </el-table-column> -->
            <el-table-column prop="medi_spec" label="规格" show-overflow-tooltip />
            <el-table-column width="120" property="approval_no" label="国药准字" show-overflow-tooltip />
        <el-table-column width="180" property="company" label="厂家" show-overflow-tooltip />
            <!-- <el-table-column prop="dict_type_name" label="类型" /> -->
            <el-table-column label="库存数量" width="100">
              <template #default="scope">
                <span class="psckset labelSet1">{{scope.row.store_num}}</span>
              </template>
            </el-table-column>
            <el-table-column label="盘点数量" width="180">
              <template #default="scope">
                <el-input-number v-if="scope.row.pdEdit" placeholder="填写" v-model="scope.row.check_num" :min="1" />
                <!-- <el-input-number v-if="scope.row.pdEdit" placeholder="填写"  v-model="scope.row.check_num" :min="1" @change="
                    (val) => {
                      handleNumChange(val, scope.row)
                    }
                  " /> -->
                <span v-else>未填</span>
              </template>
            </el-table-column>
            <el-table-column label="备注">
              <template #default="scope">
                <el-input v-if="scope.row.pdEdit" v-model="scope.row.remark" placeholder="填写" />
                <!-- <el-input v-if="scope.row.pdEdit" v-model="scope.row.remark" placeholder="填写" @change=" 
                    (val) => {
                      handleNumChange(val, scope.row)
                    }
                  " /> -->
                <span v-else>未填</span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="190">
              <template #default="scope">
                <el-button v-if="!scope.row.pdEdit&&scope.row.is_check==0" type="text" size="small"
                  @click="PDItem(scope.row,scope.$index)">
                  盘点
                </el-button>
                <el-button v-if="scope.row.is_check==1" type="text" size="small" :disabled='true'>
                  已盘点
                </el-button>
                <el-button v-if="scope.row.pdEdit&&scope.row.is_check==0" type="text" size="small"
                  @click="saveItem(scope.row,scope.$index)">
                  保存
                </el-button>
                <el-popconfirm v-if="scope.row.pdEdit&&scope.row.is_check==0" title="是否确定取消?"
                  @confirm="caseItem(scope.row,scope.$index)">
                  <template #reference>
                    <el-button type="text" size="small">
                      取消
                    </el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
          <div class="pagination-box">
            <el-pagination v-model:currentPage="pageDataPD.currentPage" v-model:page-size="pageDataPD.pageSize"
              :page-sizes="[10, 20, 50, 100]" :background="true" layout="total, sizes, prev, pager, next, jumper"
              :total="pageDataPD.totalSum" @size-change="handleSizeChangePD"
              @current-change="handleCurrentChangePD"></el-pagination>
          </div>
        </div>
      </div>
    </div>
    <div>
      <BaseDialog :title="title" :isshow="PDMainListShow" @handleShow="
      (val) => {
        PDMainListShow = val
      }
    " width="1000px" height="auto">
        <div class="tables">
          <div class="tables-left">
            <el-table class="sss" v-loading="dataListLoading" :row-style="rowStyle" :data="tableDataHistory" border
              max-height="40vh" style="width: 100%">
              <el-table-column prop="check_date" label="盘点日期" />
              <el-table-column label="计算数量" width="150">
                <template #default="scope">
                  <span>
                    {{ scope.row.store_num }} {{ scope.row.drug.min_package_unit_name }}
                    <template v-if="scope.row.store_sub_num">{{ scope.row.store_sub_num }} {{
                      scope.row.drug.min_unit_name }}</template>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="盘点数量" width="150">
                <template #default="scope">
                  <span>
                    {{ scope.row.check_num }} {{ scope.row.drug.min_package_unit_name }}
                    <template v-if="scope.row.check_sub_num">{{ scope.row.check_sub_num }} {{
                      scope.row.drug.min_unit_name }}</template>
                  </span>
                </template>
              </el-table-column>
              <el-table-column label="盘点偏差" width="120">
                <template #default="scope">
                    <span>
                      {{ scope.row.diff_num }} {{ scope.row.drug.min_package_unit_name }}
                      <template v-if="scope.row.diff_sub_num">{{ scope.row.diff_sub_num }} {{
                        scope.row.drug.min_unit_name }}</template>
                    </span>
                </template>
              </el-table-column>
              <el-table-column property="operator.name" label="操作人" />
              <el-table-column prop="remark" label="备注" show-overflow-tooltip />
            </el-table>
            <div class="pagination-box">
              <el-pagination v-model:currentPage="pageDataHistory.currentPage"
                v-model:page-size="pageDataHistory.pageSize" :page-sizes="[10, 20, 50, 100]" :background="true"
                layout="total, sizes, prev, pager, next, jumper" :total="pageDataHistory.totalSum"
                @size-change="handleSizeChangeHistory" @current-change="handleCurrentChangeHistory"></el-pagination>
            </div>
          </div>
        </div>
        <el-divider />
        <div style="text-align: right">
          <el-button @click="PDMainListShow = false">取消</el-button>
          <el-button type="primary" @click="PDMainListShow = false">确定</el-button>
        </div>
      </BaseDialog>
    </div>
    <!-- 盘点弹窗 -->
    <div>
      <BaseDialog title="盘点" @handleShow="(val) => {pdSetDialog = val }" :isshow="pdSetDialog" width="700px" height="auto">
        <el-descriptions :column="3" size="small" border style="margin-top:10px;">
          <el-descriptions-item label="药品名称">{{ drug.name }}</el-descriptions-item>
          <el-descriptions-item label="规格">{{ drug.medi_spec }}</el-descriptions-item>
          <el-descriptions-item label="类别">{{ drug.fee_type }}</el-descriptions-item>
          <el-descriptions-item label="生产厂家">{{ drug.company }}</el-descriptions-item>
        </el-descriptions>
        <div class="addform" style="margin-top:10px;">
          <el-form :model="ruleFormPd" ref="ruleFormPdRef" label-width="120px">
            <el-row>
              <el-col :span="24">
                <el-form-item label="批号">
                  <el-input disabled v-model="ruleFormPd.batch_no" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="盘点数量">
                  <el-col :span="6">
                    换算系数 {{ drug.min_package_num }}
                  </el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormPd.check_num" placeholder="">
                      <template #append>{{ drug.min_package_unit_name }}</template>
                    </el-input>
                  </el-col>
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-input v-model="ruleFormPd.check_sub_num" placeholder="">
                      <template #append>{{ drug.min_unit_name }}</template>
                    </el-input>
                  </el-col>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="盘点时间：">
                  <el-date-picker v-model="ruleFormPd.check_date" type="date" :clearable="false"
                    value-format="YYYY-MM-DD" placeholder="请选择建立日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="24">
                <el-form-item label="备注：">
                  <el-input type="textarea" v-model="ruleFormPd.remark" />
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div style="text-align: right">
          <el-button @click="cancelPd">取消</el-button>
          <el-button type="primary" @click="handlePd()">确定</el-button>
        </div>
      </BaseDialog>
    </div>
  </div>
</template>

<script>
  import { toRefs, reactive, onMounted } from 'vue'
  import service from '@/utils/request'
  import { gitDictList, TimeYear, downLoadXlsx, TimestampToTime } from '@/utils/tool'
  import BaseDialog from '@/components/Dialog/index.vue'
  import { ElMessage, ElMessageBox } from 'element-plus'
  export default {
    components: {
      BaseDialog,
    },
    setup() {
      const state = reactive({
        formInline: {
          drug_name: '',
        },
        formData: {},
        loading: true,
        pageData: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        PDMain: true,
        tableData: [],
        dictList: [],
        PDMainListShow: false,
        title: '',
        pageDataHistory: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        tableDataHistory: [],
        pageDataPD: {
          currentPage: 1,
          pageSize: 10,
          totalSum: 0,
        },
        tableDataPD: [],
        pdData: [],
        ruleFormPd:{},
        drug:'',
        pdSetDialog:false,
      })
      onMounted(() => {
        initData()
        tobody()
      })
      // 获取字典
      const initData = async () => {
        state.dictList = await gitDictList([
          '332900000',
          '210000000',
          '103000000',
          '160000000',
          '116000000',
          '176000000',
          '231000000',
          '157000000',
          '158000000',
          '159000000',
          '339600000',
          '164000000',
          '113000000',
          '161000000',
          '100000000',
          '340200000',
          '339600000',
        ])
      }
      // 获取列表
      const tobody = async () => {
        state.loading = true
        let data = {
          size: state.pageData.pageSize,
          current: state.pageData.currentPage,
          dict_type: state.formInline.dict_type,
          drug_name: state.formInline.drug_name,
          dict_catalog_type: '160000000',
          check_date:state.formInline.check_date
        }
        let res = await service.post('/api/stockCheck/list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableData = res.data.records
            state.pageData.totalSum = res.data.total
            state.tableData.forEach(element => {
              element.pdEdit = false
            });
          } else {
            state.tableData = []
            state.pageData.totalSum = 0
          }
        }
        state.loading = false
      }
      // 条数改变时
      const handleSizeChange = (e) => {
        state.pageData.pageSize = e
        tobody()
      }
      // 页数改变时
      const handleCurrentChange = (e) => {
        state.pageData.currentPage = e
        tobody()
      }
      // 查询
      const search = () => {
        tobody()
      }
      const type = () => {
        tobody()
      }
      //点击盘点
      const PDClick = () => {
        state.PDMain = false
        tobodyPD()
        state.formData.check_date = timestampToTime()
      }
      const timestampToTime = () => {
        let date = new Date()
        let year = date.getFullYear() + '-';
        var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
        var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
        var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
        var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return year + M + D;
      }
      const tobodyPD = async () => {
        state.loading = true
        let data = {
          size: state.pageDataPD.pageSize,
          current: state.pageDataPD.currentPage,
          dict_type: state.formData.dict_type,
          name: state.formData.drug_name,
          check_date: state.formData.check_date,
          dict_catalog_type: '160000000'
        }
        let res = await service.post('/api/drug/store_list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableDataPD = res.data.records
            state.pageDataPD.totalSum = res.data.total
            state.tableDataPD.forEach(element => {
              element.pdEdit = false
              element.check_num = undefined
              element.remark = ''
            });
          } else {
            state.tableDataPD = []
            state.pageDataPD.totalSum = 0
          }
        }
        state.loading = false
      }
      // 条数改变时
      const handleSizeChangePD = (e) => {
        state.pageDataPD.pageSize = e
        tobodyPD()
      }
      // 页数改变时
      const handleCurrentChangePD = (e) => {
        state.pageDataPD.currentPage = e
        tobodyPD()
      }
      //点击盘点返回事件
      const PDBackClick = () => {
        state.PDMain = true
        tobody()
      }
      const PDItem = (val, index) => {
        val.pdEdit = true
        console.log(val)
      }
      //保存
      const saveItem = async (val, index) => {
        console.log(val)
        let userData = JSON.parse(localStorage.getItem('ms_userData'))
        let data = {
          drug_id: val.id,
          check_num: val.check_num,
          check_date: state.formData.check_date,
          remark: val.remark,
          dict_catalog_type: '160000000',
          // record_id: val.id,
          // operator_id: userData.id
        }
        let res = await service.post('/api/stockCheck/save', data)
        if (res.code == 0) {
          val.pdEdit = false
          ElMessage({
            message: '盘点成功',
            type: 'success',
          })
          tobodyPD()
        }
      }
      //取消
      const caseItem = (val, index) => {
        console.log(val, index)
        val.pdEdit = false
        val.check_num = undefined
        val.remark = ''
      }
      // 查询
      const searchPD = () => {
        tobodyPD()
      }
      const typePD = () => {
        tobodyPD()
      }
      const timeChange = (e) => {
        state.formData.check_date = TimeYear(e)
        tobodyPD()
      }
      const seeHistory = (row) => {
        console.log(row)
        state.PDMainListShow = true
        state.title = '盘点历史-' + row.drug.name + row.order_no +'/' + row.batch_no
        tobodyHistory(row.drug_id, row.drug.name)
      }
      const tobodyHistory = async (id, name) => {
        state.loading = true
        let data = {
          size: state.pageDataHistory.pageSize,
          current: state.pageDataHistory.currentPage,
          dict_type: state.formInline.dict_type,
          drug_name: name,
          check_date: state.formData.check_date,
          drug_id: id,
          dict_catalog_type: '160000000'
        }
        let res = await service.post('/api/stockCheck/list', data)
        if (res.code === 0) {
          if (res.data.total > 0) {
            state.tableDataHistory = res.data.records
            state.pageDataHistory.totalSum = res.data.total
          } else {
            state.tableDataHistory = []
            state.pageDataHistory.totalSum = 0
          }
        }
        state.loading = false
      }
      // 导出
      const exportTable = (row) => {
        let data = {
          dict_type: state.formInline.dict_type,
          drug_name: state.formInline.drug_name,
          check_date: state.formData.check_date,
          dict_catalog_type: '160000000'
        }
        let time = TimestampToTime()
        let title = `药品盘点${time}.xlsx`
        downLoadXlsx('/api/stockCheck/check_export', data, title)
      }
      const handleSelectionChange = async (row) => {
        if (row.length > 0) {
          state.pdData = row
        } else {
          state.pdData = []
        }
      }
      //一键盘点
      const PDDataClick = async () => {
        if (state.pdData.length > 0) {
          let data = []
          state.pdData.forEach(item => {
            data.push(item.id)
          })
          let params = {
            drug_id_list: data,
            check_date: state.formData.check_date,
            dict_catalog_type: '160000000',
          }
          let res = await service.post('/api/stock_drug_check/create_list', params)
          if (res.code == 0) {
            ElMessage.success('盘点成功')
            tobodyPD()
          }
        } else {
          ElMessage.error('请选择盘点库存')
        }

      }
      // 修改
    const modParient = async (row) => {
      state.ruleFormPd = row
      state.drug = row.drug
      state.pdSetDialog = true
    }
    // 删除
    const stockDelete = async (row) => {
      let data = {
        id: row.id
      }
      let res = await service.post('/api/store_drug_check/delete_check', data)
      if (res.code == 0) {
        ElMessage({
          message: '撤销成功',
          type: 'success',
        })
        tobody()
      }
    }
    const cancelPd = () => {
      state.pdSetDialog = false
      state.ruleFormPd = {}
    }
    // 盘点
    const handlePd = async () => {
      let url = '/api/store_drug_check/edit_check'
      let data = {
        id: state.ruleFormPd.id,
        check_sub_num:state.ruleFormPd.check_sub_num,
        check_num:state.ruleFormPd.check_num,
        check_date:state.ruleFormPd.check_date,
        remark:state.ruleFormPd.remark
      }
      await service.post(url, data)
      state.pdSetDialog = false
      state.ruleFormPd = {}
      tobody()
    }
      return {
        ...toRefs(state),
        tobody,
        handleSizeChange,
        handleCurrentChange,
        search,
        PDClick,
        PDBackClick,
        PDItem,
        initData,
        seeHistory,
        caseItem,
        saveItem,
        type,
        searchPD,
        typePD,
        timeChange,
        exportTable,
        tobodyHistory,
        tobodyPD,
        handleSizeChangePD,
        handleCurrentChangePD,
        handleSelectionChange,
        PDDataClick,
        modParient,
        stockDelete,
        cancelPd,
        handlePd
      }
    },
  }
</script>

<style scoped lang="scss">
  .displya_flex {
    display: flex;
    justify-content: space-between;
  }

  .usertop {
    margin-bottom: 8px;
    height: 35px;
    display: flex;
    justify-content: space-between;

    &:deep(.el-alert--warning.is-light) {
      background-color: rgba(49, 102, 174, 0.1);
      color: #3166ae;
    }
  }

  .tabctext {
    height: 20px;
    overflow: hidden;
    cursor: pointer;
    color: rgba(49, 102, 174, 1);
  }
</style>
<style scoped lang="scss">
  .adviceBox {
    height: 100%;
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    padding: 10px;
  }

  .demo-tabs>.el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }

  .display_flex {
    display: flex;
    justify-content: space-between;
  }

  .search-top .w-208 {
    width: 208px;

    .searchcss {
      cursor: pointer;
    }
  }

  .faicon {
    font-size: 19px;
    margin-right: 6px;
    cursor: pointer;
  }

  .title-top {
    align-items: center;
    display: flex;
  }

  .tabclis {
    height: 32px;
    background: rgba(49, 102, 174, 0.1);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    display: flex;
    padding: 3px;

    .tabclis_right {
      display: flex;
      padding-top: 2px;
    }
  }

  /* .addform {
    .el-form {
      display: flex;
      flex-wrap: wrap;

      &:deep(.el-form-item__label) {
        width: 110px !important;
        text-align: right;
      }

      &:deep(.el-input) {
        width: 200px !important;
      }

      &:deep(.el-form-item) {
        width: 45% !important;
      }

      .mt_4 {
        &:deep(.el-input) {
          width: 100px !important;
        }
      }

      .parienttag {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .tag {
          margin-right: 8px;
          margin-bottom: 8px;
          color: #409eff;
          background-color: #ecf5ff;
          height: 20px;
          line-height: 20px;
          border-radius: 3px;
          text-align: center;
          font-size: 12px;
          padding: 0 10px;
          cursor: pointer;
        }

        .tags {
          margin-right: 8px;
          margin-bottom: 8px;
          background: #409eff;
          color: #ffffff;
          border-radius: 3px;
          height: 20px;
          line-height: 20px;
          text-align: center;
          font-size: 12px;
          padding: 0 10px;
          cursor: pointer;
        }
      }
    }
  } */

  .pagination-box {
    &:deep(.el-input__inner) {
      padding-right: 15px !important;
    }
  }

  .w-144 {
    margin-top: 20px;
  }

  .stop {
    background-color: #f4a939;
  }

  .modify {
    background-color: #3166ae;
  }

  .del {
    background-color: #ffecec;
    color: #ff6b6b;
    font-weight: 700;
  }

  .top {
    height: 35px;
    margin: 8px 0;
  }

  .nameSet {
    color: blue;
    cursor: pointer;
  }

  .labelSet1 {
    width: 60px;
    height: 30px;
    line-height: 30px;
    display: block;
    text-align: center;
    color: #fff;
    border-radius: 5px;
  }

  .psckset {
    background-color: #409EFF;
  }

  .psckset1 {
    background-color: #a4cef8;
    color: #1581fc;
    border: 1px solid #1581fc;
  }

  .lcsyset {
    background-color: #c6f1b1;
    color: #3bb207;
    border: 1px solid #5bd324;
  }

  .lcsyset1 {
    background-color: #f1beb1;
    color: #f35c0c;
    border: 1px solid #f35c0c;
  }
</style>